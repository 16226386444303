html {
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body {
    overflow-x: hidden!important;
}
input:disabled {
    cursor: not-allowed;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    overflow-y: auto;
    padding: 1rem 0;
    width: 100%;
    height: 100%; 
    background: linear-gradient(to left, rgb(15, 20, 25), rgb(24, 28, 34)); 
}
.lazy-load {
    animation: scale-display .6s;
}
@keyframes scale-display {
	0% {
		opacity: 0.75;
		transform: translateY(-5%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes om-progress_track {
0%{
    transform: scaleX(1);
}
100%{
    transform: scaleX(0);
}
}
.om-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, .7);
    transform-origin: left;
    animation: om-progress_track 3s forwards;
}

@keyframes om-progress_cycle {
    0%{
        transform: scaleX(0.6);
    }
    25%{
        transform: scaleX(0);
    }
    50%{
        transform: scaleX(0.9);
    }
    75%{
        transform: scaleX(0);
    }
    100%{
        transform: scaleX(0.6);
    }
    }
.om-cycle-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, .7);
    transform-origin: 50%;
    animation: om-progress_cycle 5s infinite;
}

.om-navigation-button {
    width: 45%;
    max-width: 130px;
}

.om-navigation-bar {
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-height: 300px; 
    display: none;
}

.om-button-optional {
    background-color: #9a00f0;
    color: #fff;
    border: 1px solid transparent;
}
.om-button-optional:hover,
.om-button-optional:focus {
    background-color: #9a00f0;
    color: #fff;
}
.om-button-optional:active,
.om-button-optional.uk-active {
    background-color: #9a00f0;
    color: #fff;
}
.om-nav-container {
    max-width: 1600px;
    margin: auto;
}
.om-bg-container {
    max-width: 1500px;
    margin: auto!important;
}
.om-body-container {
    max-width: 1600px;
    margin: auto!important;
}
.zoom {
    animation: infinite-zoom 3s infinite;
}
.hide {
    visibility: hidden;
}
.upper {
    position: relative;bottom:1.8em;
}
@keyframes infinite-zoom {
    0%, 100% {
        transform: scale(0.7);
    }
    40% {
        transform: scale(1.2);
    }
}

.om-blinking {
    animation: infinite-blink 3s infinite;
}

@keyframes infinite-blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

.om-dropdown-button {
    margin: auto;
    width: 160px;
}

div.om-dropdown {
    display: block;
    opacity: 0;
    transition: opacity 0.2s linear;
    top: 43px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 0;
    width: 160px;
}

div.om-open {
    opacity: 1;
}

.om-dropdown ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.om-dropdown ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 2px;
    gap: 10px;
}

.om-parts-categories {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.om-parts-categories > * {
    display:inline-block;
    margin-left: auto;
    margin-right: auto;
    flex: none;
    padding: 5px 10px;
    position: relative;
}

.om-parts-categories > * > a:hover,
.om-parts-categories > * > a:focus {
    background-color: #f8f8f8;
    color: #666;
    text-decoration: none;
    outline: none;
}
.om-parts-categories > * > a:active {
    background-color: #f8f8f8;
    color: #666;
}

.om-parts-categories > .uk-active > a {
    background-color: #1e87f0;
    color: #fff;
}

.om-parts-categories > * > :first-child {
    padding: 5px 10px;
    background: transparent;
    color: #999;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    transition: 0.1s ease-in-out;
    transition-property: color, background-color;
}

.om-search-box {
    width: 70%;
    margin: 0 15%!important;
}
.om-search-box button {
    padding: 0!important;
}
#gif {
    text-align: center;
    color: #333;
    font-size: 1.3rem;
    margin: 15% 0;
}
.om-files td {
    padding: 16px 0 0 0;
    color: #333;
}
.om-remove-btn {
    cursor: pointer;
    color: #ec2147;
}
.om-slideshow {
    position: relative!important;
}
.om-slideshow ul {
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}
.om-img-text {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff!important;
    text-align: center;
}
.om-img-responsive {
    min-height: 330px!important;
}
.om-img-responsive li div {
    height: 330px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.error {
    color: #ec2147;
}

.om-navigation-error:hover {
    color: #ec2147;
}

.info-box {
    float: right;
    text-align: center;
    position: relative;
    padding: 15px;
    background: #d8eafc;
    color: #1e87f0;
}
.error-box {
    float: right;
    text-align: center;
    width: 55%;
    position: relative;
    padding: 15px;
    background: #fce1e7;
    color: #fe3d5e;
}
.ok-box div {
    background-color: green;
}
.ok-box {
    float: right;
    text-align: center;
    position: relative;
    padding: 15px;
    background: #edfbf6;
    color: #32d296;
}

.ok-box-alt div:nth-child(2) {
    background-color: green;
}
.ok-box-alt {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    padding: 15px;
    background: #edfbf6;
    color: green;
}

.info-box-alt {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    padding: 15px 30px;
    background: #d8eafc;
    color: #1e87f0;
}
.info-box-alt div:nth-child(2) {
    background-color: #1e87f0;
}
.error-box-alt p {
    color: #333!important;
}
.error-box-alt {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    padding: 15px;
    background: #fce1e7;
    color: #fe3d5e;
}
.error div {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    padding: 15px;
    background: #fce1e7;
    color: #fe3d5e;
}
.info div {
    text-align: left;
    position: relative;
    padding: 15px;
    background: #d8eafc;
    color: #1e87f0;
}
.ok {
    color: green;
}
.not {
    color: #ec2147;
}
.modal {
    display: none;
}

.om-folder:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease-in-out;
}

.om-table-fit td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.om-folder:hover > .icon {
    color: #0f7ae5;
}

.om-folder {
    color: #333;
    display: inline-block;
    cursor: pointer;
    width: 20%;
    margin: 10px 25px 10px;
    min-height: 216px;
    vertical-align: top;
}

.om-login {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 30px;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    position: relative;
    box-sizing: border-box;
    transition: box-shadow 0.1s ease-in-out;
    width: calc(100% * 1 / 3.001);
}
.om-login-expand {
    width: 100%!important;
}
.om-login-expand p {
    text-align: justify!important;
}
.om-login h2 {
    font-size: 1.69rem;
    line-height: 1.5;
    text-align: center;
    color: #333;
}
.om-login p {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}
.om-login span {
    text-align: center!important;
}
.info-box-alt p {
    margin: 3px auto;
}
.om-breadcrumb li {
    margin: 0;
    font-size: 1.25rem;
}
.om-breadcrumb li span svg {
    font-size: 1.15rem
}
.om-breadcrumb li span i {
    font-size: 1.15rem
}
.om-external-btn {
    font-size: 1.25rem;
    float: right;
}
.om-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    cursor: default;
    max-width: 1570px;
    margin: auto;
    padding: 15px 0!important;
}
.uk-breadcrumb > * {
    flex: none;
}

.uk-breadcrumb > * > * {
    display: inline-block;
    font-size: 0.875rem;
    color: #999;
}

.uk-breadcrumb > * > :hover,
.uk-breadcrumb > * > :focus {
    color: #666;
    text-decoration: none;
}

.uk-breadcrumb > :last-child > * {
    color: #666;
}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    content: "/";
    display: inline-block;
    margin: 0 20px;
    color: #999;
}

.om-navigation {
    cursor: pointer;
}
.om-navigation:hover {
    color: #0f7ae5!important;
}
.om-navigation, .om-event-highlight {
    transition: .25s color;
}
.om-event:hover .om-event-highlight {
    color: #0f7ae5;
}
.om-navigation-active {
    color: #0f7ae5;
}
.om-active {
    font-weight: bold;
    color: #0f7ae5!important;
}
.om-list {
    padding: 0;
    list-style: none;
}
.om-list div {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    position: relative;
    box-sizing: border-box;
    transition: box-shadow 0.1s ease-in-out;
}
.om-list-display h6 {
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 0;
}
.om-list-display p {
    margin-top: 0;
    margin-bottom: 0;
}
.om-list-display  span {
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center!important;
}
.om-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}
.om-parts {
    cursor: pointer;
    width: calc(100% * 1 / 3.421);
    margin: 2%;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    position: relative;
    box-sizing: border-box;
    transition: box-shadow 0.1s ease-in-out;
}
.om-parts-btn {
    cursor: pointer;
    font-size: 1.2rem;
}
.om-event {
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    position: relative;
    box-sizing: border-box;
    transition: box-shadow 0.1s ease-in-out;
}
.om-event * {
    height: fit-content;
}
.om-event-date h1 {
    line-height: 1.5;
    text-align: center!important;
    font-size: 256%;
    color: #333;
}
.om-event-title h4 {
    text-align: left !important;
    margin: 0;
}
.om-event-title ul {
    display: flex;
    flex-wrap: wrap;
    float: left;
    margin-top: 0;
    padding: 0;
    list-style: none;
}
.om-event-title ul li {
    margin-right: auto;
    margin-left: auto;
    font-size: 128%;
    color: #999;
}
.om-part-title h4 {
    text-align: left !important;
    margin: 0;
}
.om-part-title ul {
    display: flex;
    flex-wrap: wrap;
    float: left;
    margin-top: 0;
    padding: 0;
    list-style: none;
}
.om-part-title ul li {
    margin-right: auto;
    margin-left: auto;
    font-size: 128%;
    color: #999;
}
.om-event-desc p {
    line-height: 1.5;
    font-size: 128%;
    margin-bottom: 0;
    text-align: justify;
}
.om-event-desc ul {
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    margin-top: 0;
    padding: 0;
    list-style: none;
}
.om-event-desc ul li {
    font-size: 110%;
    color: #999;
    margin-right: auto;
    margin-left: auto;
}
.om-event-title {
    width: 21%;
}
.om-part-title {
    width: 100%;
}
.om-polls-title-n {
    width: 18%;
}
.om-polls-title {
    width: 18%;
}
.icon {
    transition: .25s color;
}
.index:hover ~ .icon {
    color: #0f7ae5;
}
.om-header-text {
    display: inline;
}
.om-cursor {
    cursor: pointer!important;
}
.om-default {
    cursor: default!important;
}
.om-text-black th, .om-body > *, .om-text-black {
    color: #333;
}
.om-calendar th {
    cursor: default;
}
.om-vertical-center {
    display:inline-block;
    vertical-align:text-bottom
}
.om-padding-xs {
    padding: 10px;
}

i.om-margin-xs {
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
}

.om-margin-xs {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.om-icon-1x {
    font-size: 1.6rem;
    vertical-align: bottom;
}

@media (min-width: 340px) and (max-width: 640px) {
    .message-box {
        margin: 0 auto 0 auto;
        width: fit-content;
    }
    .om-logout-btn {
        padding-left: 0!important;
        padding-right: 0.5rem!important;
    }

    .om-event-desc ul {
        margin: auto;
    }
    .hide-s {
        display: none;
    }
    .om-navigation-bar {
        max-height: 350px; 
    }
    .om-folder {
        width: 80%;
    }
    .om-parts {
        width: 100%;
    }
    .om-modal-max-height {
        max-height: 250px;
    }
    .om-parts-btn {
        font-size: 1.75rem;
        padding-right: 5%;
    }
    .om-login {
        width: 100%;
    }
    .om-people-title {
        width: 45%;
    }
    .om-people-date {
        width: 40%;
    }
    .om-polls-title {
        width: 55%;
    }
    .om-polls-date {
        width: 45%;
    }
    .om-polls-title-n {
        width: 60%;
    }
    .om-polls-date-n {
        width: 45%;
    }
    .om-event-title {
        width: 50%;
    }
    .om-event-icon {
        width: 15%;
        padding-left: 0;
    }
    .om-event-desc p {
        text-align: center!important;
        padding: 2%;
    }
    .om-padding-remove\@s {
        padding: 0!important;
    }
    .om-padding-remove-left\@s {
        padding: 0!important;
    }
    .om-header-text {
        font-size: 1.8rem;
    }
    .om-header-btn {
        margin-right: 2.5rem;
        margin-left: 1rem;
        margin-bottom: 0;
        padding: 0 15px;
    }
    .om-text-box {
        margin-left: 5%;
        margin-right: 5%;
    }
    .om-page-title {
        width: 75%;
        padding-right: 0;
    }
    .om-page-btn {
        width: 25%;
        padding-left: 0;
    }
}

@media (min-width: 640px) and (max-width: 1320px) {
    .message-box {
        margin: 0 0 0 auto;
        width: fit-content;
    }
    .hide-m {
        display: none;
    }
    .om-login {
        width: 50%;
    }
    .om-folder {
        width: 36%;
    }
    .om-card-responsive {
        width: 55%;
        margin: auto;
    }
    .om-page-title {
        width: 83%;
    }
    .om-page-btn {
        width: 17%;
        margin-top: 0;
    }
    .om-event-desc p {
        text-align: center!important;
    }
}

@media (min-width: 1320px) {
    .message-box {
        margin: 0 0 0 auto;
        width: fit-content;
    }
    .om-event-desc ul {
        float: right;
        text-align: right;
        max-width: 275px;
    }
    .om-modal-max-height {
        max-height: 400px;
    }
    .om-login {
        padding: 30px 40px;
    }
    .om-login h2 {
        font-size: 1.66rem;
        line-height: 1.5;
        color: #333;
    }
    .hide-l {
        display: none!important;
    }
}

/* tooltip */
[om-tooltip][aria-label] {
    position: relative;
}
[om-tooltip][aria-label]::before {
    content: attr(aria-label);
    pointer-events: none;
    position: absolute;
    padding: 3px 6px;
    background: #666;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    width: max-content;
    max-width: 200px;
    text-transform: none;
    opacity: 0;
    visibility: none;
}
[om-tooltip^="top"][aria-label]::before {
    bottom: calc(0.5rem + 100%);
    right: 50%;
    transform: translateX(50%);
}
[om-tooltip^="down"][aria-label]::before {
    top: calc(0.5rem + 100%);
    right: 50%;
    transform: translateX(50%);
}
[om-tooltip^="right"][aria-label]::before {
    left: calc(1rem + 100%);
    bottom: 50%;
    transform: translateY(50%);
}
[om-tooltip][aria-label]:hover::before {
    visibility: visible;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.message-box-expand {
    margin: 1rem;
    width: inherit;
}